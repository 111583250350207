import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { OrdersService } from './orders.service';
import { QsIdsService } from '../../services/qs-ids.service';
import Swal from 'sweetalert2';

const BACKEND_URL = environment.qsServiceAPIUrl;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  loading: boolean = false;
  token: any = '';
  activeButton: string = '';
  phoneNumber: any;
  orders: any = [];
  isChecked:any;
  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private router: Router,
    private OrdersService: OrdersService,
    private QsIdsService: QsIdsService
  ) {}

  ngOnInit() {
    this.phoneNumber = localStorage.getItem('phoneNumber');
    this.token = localStorage.getItem('token');
    if (!this.token) {
      this.router.navigate(['/login']);
      return;
    }
    this.fetchOrders();
  }


  isActiveButton: boolean = true;
  isInActiveButton: boolean = false;

  refreshPage() {
    window.location.reload();
  }

  getServiceLabel(serviceName: string): string {
    return this.QsIdsService.getServiceLabel(serviceName);
  }

  orderStatusSwitch(orderStatus: String): any {
    switch (orderStatus) {
      case 'CAN':
        return 'Cancelled';
      case 'COM':
        return 'Completed';
      case 'ACC':
        return 'Accepted';
      case 'PRO':
        return 'Progress';
      case 'ASS':
        return 'Assigned';
    }
  }

  formatDate(date: string): string {
    const formattedDate = new Date(date);
    return this.datePipe.transform(formattedDate, 'dd/MM/yyyy') || '';
  }

  activeService() {
    this.isActiveButton = true;
    this.isInActiveButton = false;
    this.fetchOrders();
  }

  inactiveService() {
    this.inactiveFetchOrders();
  }
  
  // fetchOrders(): void {
  //   // Set loading to true before making the HTTP request
  //   this.loading = true;
  //   this.http
  //     .get<any>(`${BACKEND_URL}/activeOrders/${this.phoneNumber}`)
  //     .subscribe(
  //       (response) => {
  //         console.log('response', response);
  //         this.orders = response;
  //         this.OrdersService.setOrders(this.orders);
  //         console.log(this.orders);

  //         const serviceProvider = this.orders[0].serviceProvider.find(
  //           (provider: any) => provider.phoneNumber.toString() === this.phoneNumber
  //         );

  //         console.log('the serviceProvider is: ', serviceProvider);
  //         console.log('the phoneNumber is: ', this.phoneNumber);
  //         // Set isChecked based on the found service provider's isAccepted value
  //         if (serviceProvider) {
  //           this.isChecked = serviceProvider.isAccepted;
  //           console.log('the check is: ', this.isChecked);
  //         } else {
  //           console.error(
  //             'Service provider with the specified phone number not found.'
  //           );
  //         }
  //         // Set loading to false after receiving the response
  //         this.loading = false;
  //       },
  //       (error) => {
  //         console.error('Error fetching orders:', error);
  //         // Ensure loading is set to false even if an error occurs
  //         this.loading = false;
  //       }
  //     );
  // }

  fetchOrders(): void {
    // Set loading to true before making the HTTP request
    this.loading = true;
    this.http
      .get<any>(`${BACKEND_URL}/activeOrders/${this.phoneNumber}`)
      .subscribe(
        (response) => {
          console.log('Response:', response);
          this.orders = response;
          this.OrdersService.setOrders(this.orders);
          console.log('Orders:', this.orders);
  
          // Check if orders array is defined and has at least one element
          if (Array.isArray(this.orders) && this.orders.length > 0) {
            // Check if the first order has a serviceProvider array
            if (Array.isArray(this.orders[0].serviceProvider)) {
              const serviceProvider = this.orders[0].serviceProvider.find(
                (provider: any) => provider.phoneNumber.toString() === this.phoneNumber
              );
  
              console.log('The serviceProvider is:', serviceProvider);
              console.log('The phoneNumber is:', this.phoneNumber);
  
              // Set isChecked based on the found service provider's isAccepted value
              if (serviceProvider) {
                this.isChecked = serviceProvider.isAccepted;
                console.log('The check is:', this.isChecked);
              } else {
                console.error('Service provider with the specified phone number not found.');
              }
            } else {
              console.error('The first order does not contain a serviceProvider array.');
            }
          } else {
            console.log('No orders found or orders is not an array.');
          }
  
          // Set loading to false after receiving the response
          this.loading = false;
        },
        (error) => {
          console.error('Error fetching orders:', error);
          // Ensure loading is set to false even if an error occurs
          this.loading = false;
        }
      );
  }
  

  // inactiveFetchOrders(): void {
  //   this.isActiveButton = false;
  //   this.isInActiveButton = true;
  //   this.loading = true;

  //   this.http
  //     .get<any>(`${BACKEND_URL}/inActiveOrders/${this.phoneNumber}`)
  //     .subscribe(
  //       (response) => {
  //         this.orders = response;
  //         this.OrdersService.setOrders(this.orders);
  //         console.log(this.orders);
  //         const serviceProvider = this.orders[0].serviceProvider.find(
  //           (provider: any) => provider.phoneNumber.toString() === this.phoneNumber
  //         );

  //         console.log('the serviceProvider is: ', serviceProvider);
  //         console.log('the phoneNumber is: ', this.phoneNumber);
  //         // Set isChecked based on the found service provider's isAccepted value
  //         if (serviceProvider) {
  //           this.isChecked = serviceProvider.isAccepted;
  //           console.log('the check is: ', this.isChecked);
  //         } else {
  //           console.error(
  //             'Service provider with the specified phone number not found.'
  //           );
  //         }
  //         // Set loading to false after receiving the response
  //         this.loading = false;
  //       },
  //       (error) => {
  //         console.error('Error fetching orders:', error);
  //         // Ensure loading is set to false even if an error occurs
  //         this.loading = false;
  //       }
  //     );
  // }


  inactiveFetchOrders(): void {
    this.isActiveButton = false;
    this.isInActiveButton = true;
    this.loading = true;
  
    this.http
      .get<any>(`${BACKEND_URL}/inActiveOrders/${this.phoneNumber}`)
      .subscribe(
        (response) => {
          this.orders = response;
          this.OrdersService.setOrders(this.orders);
          console.log(this.orders);
  
          // Check if orders array is defined and not empty
          if (Array.isArray(this.orders) && this.orders.length > 0) {
            const serviceProvider = this.orders[0].serviceProvider.find(
              (provider: any) => provider.phoneNumber.toString() === this.phoneNumber
            );
  
            console.log('The serviceProvider is: ', serviceProvider);
            console.log('The phoneNumber is: ', this.phoneNumber);
  
            // Set isChecked based on the found service provider's isAccepted value
            if (serviceProvider) {
              this.isChecked = serviceProvider.isAccepted;
              console.log('The check is: ', this.isChecked);
            } else {
              console.error('Service provider with the specified phone number not found.');
            }
          } else {
            console.log('No orders found or orders is not an array.');
          }
  
          // Set loading to false after receiving the response
          this.loading = false;
        },
        (error) => {
          console.error('Error fetching orders:', error);
          // Ensure loading is set to false even if an error occurs
          this.loading = false;
        }
      );
  }
  

  goToDescriptionPage(orderID: any) {
    this.router.navigate(['/description'], {
      queryParams: { serviceID: orderID },
    });
  }

  goToProfilePage() {
    this.router.navigate(['/profile']);
  }
}
