<div class="container">
  <div class="row m-4 ms-0 me-0">
    <div (click)="refreshPage()" class="col d-flex align-items-center">
      <i class="fa-solid fa-arrows-rotate fa-2xl"></i>
    </div>

    <div class="col" style="text-align: center">
      <a class="navbar-brand" href="#">
        <img src="assets/logos/pocketmartNavbar.png" alt="Logo" width="150" />
      </a>
    </div>
    <div
      (click)="goToProfilePage()"
      class="col d-flex justify-content-end align-items-center"
    >
      <i class="fa-solid fa-user fa-2xl"></i>
    </div>
  </div>
  <hr />
  <div class="row">
    <div class="col">
      <button
        (click)="activeService()"
        type="button"
        class="btn btn-secondary tabButton"
        [ngClass]="{'active': isActiveButton}"
      >
        Active
      </button>
    </div>
    <div class="col">
      <button
        (click)="inactiveFetchOrders()"
        type="button"
        class="btn btn-secondary tabButton"
        [ngClass]="{'inactive': isInActiveButton}"
      >
        Completed
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="card mt-3 p-4" *ngFor="let orders of orders">
        <p class="headerText">
          {{ getServiceLabel(orders.serviceType) }}
          <i
            *ngIf="isChecked"
            class="fa-solid fa-lock"
            style="color: #44c7a0; float: right"
          ></i>
          <i
            *ngIf="!isChecked"
            class="fa-solid fa-lock-open"
            style="color: #44c7a0; float: right"
          ></i>
        </p>

    

        <hr />
    
        <div class="row">
          <div class="col">
            <p>Date: {{ formatDate(orders.orderForDate) }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="userName">{{ orders.userDetails.userName }}</p>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <p>
              <i class="fa-solid fa-location-dot"></i>
              {{ orders.address.building }}
            </p>
            <p>{{ orders.address.street }}</p>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">
            <p>
              <i class="fa-solid fa-phone-volume"></i>
              {{ orders.address.deliveryNumber }}
            </p>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col">
            <p class="serviceID">ServiceID: {{ orders.orderID }}</p>
          </div>
          <div class="col-1"></div>
        </div>
        <div
          class="row mt-2 showMore"
          (click)="goToDescriptionPage(orders.orderID)"
        >
          <div class="col">
            <p>show more</p>
          </div>
        </div>
      </div>

      

      <div class="mt-3 p-4" *ngIf="orders && orders.length < 1">
       
        <p style="text-align: center;">  <i
          class="fa-solid fa-box fa-beat-fade fa-2xl"
          style="color: #949699"
        ></i> &nbsp; <span style="font-size: x-large; font-weight: bold;">Nothing to display</span></p>
      </div>
    </div>
  </div>
</div>
