import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  orders: any[] = [];
  nearestOrders: any[] = [];

  constructor() { }

  setOrders(orders: any[]): void {
    this.orders = orders;
  }

  getOrders(): any[] {
    return this.orders;
  }

  setNearestOrders(nearestOrders: any[]): void {
    this.nearestOrders = nearestOrders;
  }

  getNearestOrders(): any[] {
    return this.nearestOrders;
  }

}
