<div class="container">
  <div class="row m-4 me-0 ms-0 align-items-center">
    <div class="col d-flex align-items-center" (click)="goBack()">
      <i class="fa-solid fa-arrow-left fa-2xl"></i>
    </div>
    <div class="col text-center">
      <a class="navbar-brand">
        <img src="assets/logos/pocketmartNavbar.png" alt="Logo" width="150" />
      </a>
    </div>
    <div class="col d-flex justify-content-end"></div>
  </div>
</div>
<hr />
<div class="container">
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col">
          <img
            src="../../../assets/defaultImage/defaultimage.jpg"
            class="rounded-circle profile-img"
            alt="User Image"
          />
        </div>
        <div class="col-7">
          <p style="margin-bottom: 0px;">Service completed : <b style="float: right;">{{completedServices}}</b> </p>
          <p style="margin-bottom: 0px;">Total amount : <b  style="float: right;">{{totalAmount}}</b></p>
        </div>
      </div>
    </div>
    <!-- <hr style="margin: 0;"> -->
    <div class="card-body">
      <button
        (click)="goToProfilePageDetails()"
        type="button"
        class="btn btn-secondary"
      >
        Profile Details
      </button>
      <br />
      <button (click)="getQRcode()" type="button" class="btn btn-secondary">
        QRcode
      </button>
      <br />
      <button (click)="logout()" type="button" class="btn btn-secondary">
        Logout
      </button>
    </div>
  </div>
</div>

<div class="containe" *ngIf="QRCode && QRCode.length > 0">
  <div class="row p-3">
    <img class="QRcodeImage" [src]="QRCode" alt="QRCode" />
  </div>
</div>
