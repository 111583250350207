import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { DescriptionComponent } from './components/description/description.component'
import { ProfilePageComponent } from './components/profile-page/profile-page.component';
import { ProfileDetailsComponent } from './components/profile-details/profile-details.component';
import { AuthGuard } from './auth.guard';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent},
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard],},
  { path: 'description', component: DescriptionComponent,canActivate: [AuthGuard],},
  { path: 'profile', component: ProfilePageComponent,canActivate: [AuthGuard],},
  { path: 'profile-details', component: ProfileDetailsComponent, canActivate: [AuthGuard],},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
