import { Component } from '@angular/core';
import { Router } from '@angular/router'; 
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';
import { environment } from '../../../environments/environment';
const BACKEND_URL = environment.qsServiceAPIUrl;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  constructor(private router: Router, private http: HttpClient) { }

  phoneNumber: any;
  keyword: any;
  errorMessage: any;

  login(): void {
    this.http.post<any>(`${BACKEND_URL}/qsLogin`, { phoneNumber: this.phoneNumber, keyword: this.keyword })
      .subscribe(
        response => {
          if (response.token) {
            console.log(response);
            localStorage.setItem('token', response.token);
            localStorage.setItem('phoneNumber', this.phoneNumber);
            this.clearFields();
            // Redirect to /home route if login is successful
            this.router.navigate(['/home']);
          } else {
            this.errorMessage = 'Invalid phone number or keyword';
          }
        },
        error => {
          console.error('Error logging in:', error);
          if (error.status === 401) {
            // Show SweetAlert for invalid password
            Swal.fire({
              icon: 'error',
              title: 'Invalid Keyword',
              text: 'Please check your phone number and keyword and try again',
            });
          } else {
            this.errorMessage = 'An error occurred while logging in';
            Swal.fire({
              icon: 'error',
              title: 'An error occured',
              text: 'An error occured please try again',
            });
          }
        }
      );
  }

  clearFields(){
    this.phoneNumber = null;
    this.keyword = null;
  }

}
