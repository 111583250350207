<div class="container">
  <div class="row m-4 me-0 ms-0">
    <div class="col d-flex align-items-center" (click)="goBack()">
      <i class="fa-solid fa-arrow-left fa-2xl"></i>
    </div>

    <div class="col" style="text-align: center">
      <a class="navbar-brand" href="#">
        <img src="assets/logos/pocketmartNavbar.png" alt="Logo" width="150" />
      </a>
    </div>
    <div class="col"></div>
  </div>
  <hr />

  <div class="div" *ngFor="let orderDetails of orderDetails">
    <div class="row">
      <div class="col">
        <div class="form-check form-switch">
          <input
            [(ngModel)]="isChecked"
            (change)="onToggle($event)"
            class="form-check-input"
            type="checkbox"
            id="flexSwitchCheckChecked"
            checked
            [disabled]="isChecked"
          />
          <label
            class="form-check-label acceptService"
            for="flexSwitchCheckChecked"
            >Accept</label
          >
        </div>
      </div>
      <div class="col">
        <!-- <p class="serviceType">
          {{ getServiceLabel(orderDetails.serviceType) }}
        </p> -->
        <!-- <p class="serviceDate">{{ formatDate(orderDetails.orderForDate) }}</p> -->
      </div>
    </div>
    <div class="row">
      <p class="serviceType">
        {{ getServiceLabel(orderDetails.serviceType) }}
      </p>
      <p class="serviceDate">
        {{ formatDate(orderDetails.orderForDate) }}
        <span *ngIf="orderDetails.endDate"
          >-{{ formatDate(orderDetails.endDate) }}</span
        >
      </p>
    </div>
    <div class="row">
      <div class="col">
        <p class="userName">{{ orderDetails.userDetails.userName }}</p>
      </div>
    </div>
    <div class="row mt-1">
      <div class="col">
        <p class="serviceAddressTitle">Service Address</p>
        <div class="row m-1">
          <div class="col">
            <p>{{ orderDetails.address.building }}</p>
            <p>{{ orderDetails.address.street }}</p>
            <p>{{ orderDetails.address.district }}</p>
            <p>{{ orderDetails.address.pinCode }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-1" *ngIf="orderDetails.serviceWorkDescription">
      <div class="col">
        <p class="serviceDescription">Service Description</p>
        <div class="row m-1">
          <div class="col">
            <p>{{ orderDetails.serviceWorkDescription }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-3">
      <div class="col">
        <p class="phoneNumber">
          <i class="fa-solid fa-phone-volume"></i>&nbsp;
          <u>
            <a [href]="'tel:' + orderDetails.address.deliveryNumber">
              +91 {{ orderDetails.address.deliveryNumber }}</a
            >
          </u>
        </p>
      </div>
      <div
        style="text-align: right"
        class="col"
        *ngIf="orderDetails.address.location"
      >
        <p
          (click)="
            openGoogleMapsDirections(
              orderDetails.address.location.coordinates[0],
              orderDetails.address.location.coordinates[1]
            )
          "
        >
          <i class="fa-solid fa-map-location-dot"></i>
          <span class="showMap"> Show on map</span>
        </p>
      </div>
    </div>

    <hr />
    <div class="div card p-2">
      <div class="row mb-2" style="color: gray">
        <div class="col">Booking price</div>
        <div class="col" style="text-align: center">
          {{ orderDetails.ratePerHour || 0 }} /
          {{ orderDetails.rupeesUnit || '' }}
        </div>
      </div>

      <div class="row">
        <div class="col">Amount Paid</div>
        <div class="col" style="text-align: center">
          {{ orderDetails.totalServicePrice || 0 }}
        </div>
      </div>

      <div class="row">
        <div class="col">Working Hours</div>
        <div class="col" style="text-align: center">
          {{ orderDetails.totalWorkingHours || 0 }} hours
        </div>
      </div>

      <div class="row">
        <div class="col mt-1">
          <p>Service status</p>
        </div>
        <div class="col">
          <p class="serviceStatus">
            {{ orderStatusSwitch(orderDetails.orderStatus) }}
          </p>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col">
          <p>Payment status</p>
        </div>
        <div class="col">
          <p class="paymentStatus">
            {{ paymentStatus(orderDetails.paymentStatus) }}
          </p>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col">
          <p>Payment Method</p>
        </div>
        <div class="col">
          <p class="paymentMethod">
            {{ paymentMethod(orderDetails.paymentMethod) }}
          </p>
        </div>
      </div>

      <div class="row mt-1">
        <div class="col">
          <p class="serviceID">
            ServiceID :
            <span style="font-weight: bold">{{ orderDetails.orderID }}</span>
          </p>
        </div>
      </div>
    </div>

    <!-- <div class="row" *ngIf="isChecked">
      <div class="col m-4 p-4" *ngIf="orderDetails.orderStatus !== 'COM'">
        <button type="button" class="btn btn-success">Success</button>
      </div>
    </div> -->

    <div class="row" *ngIf="isChecked">
      <div class="col m-4 p-4 card" *ngIf="orderDetails.orderStatus !== 'COM'">
        <p style="font-size: larger">
          <b><u>Service completion report</u></b>
        </p>

        <div class="row">
          <p>Payment Method:</p>
        </div>

        <div class="row mt-2 payment-method">
          <div class="row">
            <div class="col" style="text-align: center">
              <label class="payment-option">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="UPI"
                  (change)="setPaymentMethod('UPI')"
                />
                UPI
              </label>
            </div>
            <div class="col">
              <label class="payment-option">
                <input
                  type="radio"
                  name="paymentMethod"
                  value="COD"
                  (change)="setPaymentMethod('COD')"
                />
                COD
              </label>
            </div>
          </div>
        </div>
        <div class="row mt-1">
          <div class="col">
            <p>Hours worked :</p>
          </div>
          <div class="col">
            <input
              type="number"
              [(ngModel)]="hoursWorked"
              class="form-control"
              id="hoursWorked"
              aria-describedby="hoursWorkedHelp"
              min="0"
              max="99"
              oninput="this.value = this.value.slice(0, 2)"
            />
          </div>
        </div>
        <div class="row mt-1">
          <div class="col">
            <p>Total amount :</p>
          </div>
          <div class="col">
            <input
              type="number"
              [(ngModel)]="totalAmount"
              class="form-control"
              id="totalAmount"
              aria-describedby="totalAmountHelp"
              min="0"
              oninput="this.value = this.value.slice(0, 5)"
            />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col mb-4">
            <button
              (click)="save()"
              style="width: 100%"
              type="button"
              class="btn btn-success"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
