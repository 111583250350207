<div class="container-fluid d-flex align-items-center justify-content-center vh-100">
    <div class="text-center">
      <img src="assets\logos\pocketmart.png" alt="Logo" class="mb-4 logo"> <!-- Added the 'logo' class -->
      <br><br>
      <h1 class="loginHeading">Quick Serve <br> Service Login</h1>
      <br>
      <form>
        <div class="form-group">
          <input type="text" maxlength="10" class="form-control input-field" placeholder="Phone number"  [(ngModel)]="phoneNumber"  [ngModelOptions]="{ standalone: true }" required> <!-- Added the 'input-field' class -->
        </div>
        <div class="form-group">
          <input type="text" maxlength="10" class="form-control input-field" placeholder="Keyword" [(ngModel)]="keyword"  [ngModelOptions]="{ standalone: true }" required> <!-- Added the 'input-field' class -->
        </div>
        <br><br>
        <button (click)="login()" type="submit" class="btn btn-success btn-block">Login</button>
      </form>
      <br><br><br><br>

      <i class="fa-solid fa-screwdriver-wrench fa-3x" style="color: rgb(187, 198, 204);"></i>
    </div>
  </div>
  