import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DatePipe } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { QsIdsService } from '../../services/qs-ids.service';

const BACKEND_URL = environment.qsServiceAPIUrl;

@Component({
  selector: 'app-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.css']
})
export class ProfileDetailsComponent implements OnInit {

  constructor(
    private http: HttpClient,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private location: Location,
    private router: Router,
    private QsIdsService: QsIdsService
  ) {}

  phoneNumber: any;
  profileDetails: any;

  localBodyType: any;
  localBodyName: any;
  wardNumber: any;
  wardName: any;
  districtName: any;
  idProofName: any;
  idProofNumber: any;
  idProofNamePath: any;
  profileID: any;
  username: any;
  primaryMobileNumber: any;
  secondaryMobileNumber: any;
  whatsAppNumber: any;
  serviceName: any;
  active: any;
  delete: any;
  experience: any;
  registeredDate: any;

 
  ngOnInit() {
    const phoneNumber = localStorage.getItem('phoneNumber');
    this.phoneNumber = phoneNumber;

    if (this.phoneNumber) {
      this.fetchOrderDetails();
    }
  }

  goBack() {
    this.location.back();
  }
  
  fetchOrderDetails() {
    // Make a GET request to fetch order details
    this.http.get(`${BACKEND_URL}/profilePage/${this.phoneNumber}`).subscribe(
      (response) => {
        console.log('response : ', response);
        this.profileDetails = response;
        this.assignDetails(this.profileDetails[0]);
      },
      (error) => {
        console.error('Error fetching order details:', error);
        // Handle error, show an error message, etc.
      }
    );
  }


  assignDetails(details: any): void {
    const {
      applicationDetails: {
        localBodyType,
        localBodyName,
        wardNumber,
        wardName,
        districtName,
        idProofName,
        idProofNumber,
        idProofNamePath
      },
      profileID,
      username,
      primaryMobileNumber,
      secondaryMobileNumber,
      whatsAppNumber,
      serviceName,
      active,
      delete: isDelete,
      experience,
      registeredDate
    } = details;

    this.localBodyType = localBodyType;
    this.localBodyName = localBodyName;
    this.wardNumber = wardNumber;
    this.wardName = wardName;
    this.districtName = districtName;
    this.idProofName = idProofName;
    this.idProofNumber = idProofNumber;
    this.idProofNamePath = idProofNamePath;
    this.profileID = profileID;
    this.username = username;
    this.primaryMobileNumber = primaryMobileNumber;
    this.secondaryMobileNumber = secondaryMobileNumber;
    this.whatsAppNumber = whatsAppNumber;
    this.serviceName = serviceName;
    this.active = active;
    this.delete = isDelete;
    this.experience = experience;
    this.registeredDate = new Date(registeredDate);

    
  }

}
