import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QsIdsService {
  // private serviceLabels :any = {
  //   qs_ID_01: 'Driver',
  //   qs_ID_02: 'Plumber',
  //   qs_ID_03: 'Plumber2',
  //   qs_ID_04: 'Plumber3',
  //   qs_ID_05: 'Plumber4',
  //   qs_ID_06: 'Plumber5',
  //   qs_ID_07: 'Plumber6',
  //   qs_ID_08: 'Plumber7',
  //   qs_ID_09: 'Plumber8',
  // };

  private serviceLabels:any = {
    qs_ID_01: 'House maid',
    qs_ID_02: 'Maintenance (Res/Comm)',
    qs_ID_03: 'Child care',
    qs_ID_04: 'Post natal (support)',
    qs_ID_05: 'Bystander (elderly care)',
    qs_ID_06: 'Bystander (bedridden)',
    qs_ID_07: 'Vehicle cleaning',
  };

  constructor() { }

  getServiceLabel(serviceName: string): string {
    console.log('the service label is: ' + serviceName);
    return this.serviceLabels[serviceName] || 'Unknown';
  }

  
}
