<div class="container">
    <div class="row m-4 me-0 ms-0 align-items-center">
      <div class="col d-flex align-items-center" (click)="goBack()">
          <i class="fa-solid fa-arrow-left fa-2xl"></i>
      </div>
      <div class="col text-center">
        <a class="navbar-brand" >
          <img src="assets/logos/pocketmartNavbar.png" alt="Logo" width="150" />
        </a>
      </div>
      <div class="col d-flex justify-content-end">
        
      </div>
    </div>
  </div>
  <hr />
  <div class="container">
    <div class="card">
      <div class="card-header text-center">
        <img
          src="../../../assets/defaultImage/defaultimage.jpg"
          class="rounded-circle profile-img"
          alt="User Image"
        />

      </div>
      <div class="card-body">
        <form>
          <div class="form-group">
            <label for="profileID">Profile ID</label>
            <input
              type="text"
              class="form-control"
              id="profileID"
              [value]="profileID"
              readonly
            />
          </div>
          <div class="form-group mt-2">
            <label for="username">Username</label>
            <input
              type="text"
              class="form-control"
              id="username"
              [value]="username"
              readonly
            />
          </div>
          <div class="form-group mt-2">
            <label for="registeredDate">Registered Date</label>
            <input
              type="text"
              class="form-control"
              id="registeredDate"
              [value]="registeredDate | date : 'medium'"
              readonly
            />
          </div>
          <div class="form-group mt-2">
            <label for="primaryMobileNumber">Primary Mobile Number</label>
            <input
              type="tel"
              class="form-control"
              id="primaryMobileNumber"
              [value]="primaryMobileNumber"
              readonly
            />
          </div>
          <div class="form-group mt-2">
            <label for="secondaryMobileNumber">Secondary Mobile Number</label>
            <input
              type="tel"
              class="form-control"
              id="secondaryMobileNumber"
              [value]="secondaryMobileNumber"
              readonly
            />
          </div>
          <div class="form-group mt-2">
            <label for="whatsAppNumber">WhatsApp Number</label>
            <input
              type="tel"
              class="form-control"
              id="whatsAppNumber"
              [value]="whatsAppNumber"
              readonly
            />
          </div>
          <!-- <div class="form-group">
            <label for="serviceName">Service Name</label>
            <input
              type="text"
              class="form-control"
              id="serviceName"
              [value]="serviceName"
              readonly
            />
          </div> -->
          <div class="form-group mt-2 mb-2">
            <label for="experience">Experience</label>
            <input
              type="text"
              class="form-control"
              id="experience"
              [value]="experience"
              readonly
            />
          </div>
          
  
          <!-- <div class="form-group">
            <label for="idProofName">ID Proof Name</label>
            <input
              type="text"
              class="form-control"
              id="idProofName"
              [value]="idProofName"
              readonly
            />
          </div> -->
          <!-- <div class="form-group">
            <label for="idProofNumber">ID Proof Number</label>
            <input
              type="text"
              class="form-control"
              id="idProofNumber"
              [value]="idProofNumber"
              readonly
            />
          </div> -->
  
          <!-- <div class="form-group">
            <label for="localBodyType">Local Body Type</label>
            <input
              type="text"
              class="form-control"
              id="localBodyType"
              [value]="localBodyType"
              readonly
            />
          </div> -->
          <!-- <div class="form-group">
            <label for="localBodyName">Local Body Name</label>
            <input
              type="text"
              class="form-control"
              id="localBodyName"
              [value]="localBodyName"
              readonly
            />
          </div> -->
          <!-- <div class="form-group">
            <label for="wardNumber">Ward Number</label>
            <input
              type="text"
              class="form-control"
              id="wardNumber"
              [value]="wardNumber"
              readonly
            />
          </div> -->
          <!-- <div class="form-group">
            <label for="wardName">Ward Name</label>
            <input
              type="text"
              class="form-control"
              id="wardName"
              [value]="wardName"
              readonly
            />
          </div> -->
          <!-- <div class="form-group">
            <label for="districtName">District Name</label>
            <input
              type="text"
              class="form-control"
              id="districtName"
              [value]="districtName"
              readonly
            />
          </div> -->
        </form>
      </div>
    </div>
  </div>
  