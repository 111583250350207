import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class QsIdsService {
  // private serviceLabels :any = {
  //   qs_ID_01: 'Driver',
  //   qs_ID_02: 'Plumber',
  //   qs_ID_03: 'Plumber2',
  //   qs_ID_04: 'Plumber3',
  //   qs_ID_05: 'Plumber4',
  //   qs_ID_06: 'Plumber5',
  //   qs_ID_07: 'Plumber6',
  //   qs_ID_08: 'Plumber7',
  //   qs_ID_09: 'Plumber8',
  // };

  private serviceLabels:any = {
    qs_ID_01: 'Household cleaning',
    qs_ID_02: 'Cooking',
    qs_ID_03: 'Cooking and cleaning',
    qs_ID_04: 'Care taker',
    qs_ID_05: 'Vehicle washing',
    qs_ID_06: 'Deep cleaning units',
  };

  constructor() { }

  getServiceLabel(serviceName: string): string {
    console.log('the service label is: ' + serviceName);
    return this.serviceLabels[serviceName] || 'Unknown';
  }

  
}
